import React from 'react';
import Slider from 'react-slick';
import styles from './HeroHeader.module.css';
// import WhatsappBtn from '../WhatsappBtn';
import content1 from '../../assets/images/promo/promo1.png'; // First photo
import content2 from '../../assets/images/promo/promo2.png'; // Second photo
import content4 from '../../assets/images/promo/promo4.png'; // Second photo

const HeroHeader = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true, // Auto slide every 3 seconds
        autoplaySpeed: 3000 // 3 seconds
    };

    return (
        <div className={styles.heroContainer}>
            <div className={styles.sliderContainer}>
                <Slider {...settings}>
                    <div>
                        <img src={content2} alt="Hero 1" className={styles.heroImage} />
                    </div>
                    <div>
                        <img src={content1} alt="Hero 2" className={styles.heroImage} />
                    </div>
                    <div>
                        <img src={content4} alt="Hero 4" className={styles.heroImage} />
                    </div>
                </Slider>
            </div>
            {/* Optional: Uncomment if you need the text container */}
            {/* <div className={styles.textContainer}>
                <h1 className={styles.headerText}>Personalized Cards & Invitations</h1>
                <p className={styles.subText}>"Handing value to your loved ones."</p>
                <WhatsappBtn title='Whatsapp Us'/>
            </div> */}
        </div>
    );
};

export default HeroHeader;
